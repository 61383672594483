<template>
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 13.5L12 7.5M12 7.5L6 1.5M12 7.5L0 7.5"
      :stroke="getAssets.primaryBrandColor || '#A6383C'"
      stroke-width="2"
    />
  </svg>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Side-Arrow-Svg",
  computed: {
    ...mapGetters(["getAssets"]),
  },
};
</script>
