<template>
  <nav class="pt-4 w-100">
    <div
      :class="$route.name === 'SwitchApplication' ? 'container-fluid' : 'container'"
      class="d-flex justify-content-between align-items-center"
    >
      <div class="logo">
        <img
          class="width-175"
          v-if="getAssets && getAssets.horizontalTeamLogo"
          :src="getAssets.horizontalTeamLogo"
          alt="Team-Logo"
        />
      </div>
      <button
        type="button"
        :class="openSideBar ? 'active' : ''"
        @click="showSidebar"
        class="burger"
        id="burger"
      >
        <span class="burger-line"></span>
        <span class="burger-line"></span>
        <span class="burger-line"></span>
        <span class="burger-line"></span>
      </button>
      <div class="dropdown" v-if="getIsAuthenticated">
        <button
          type="button"
          id="my-account"
          class="dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          My Account
          <down-arrow />
        </button>
        <ul class="dropdown-menu" aria-labelledby="my-account">
          <li>
            <router-link to="/switch-application" class="dropdown-item"
              >Switch Application</router-link
            >
          </li>
          <li>
            <router-link to="/change-email" class="dropdown-item"
              >Change Email</router-link
            >
          </li>
          <li>
            <router-link to="/change-password" class="dropdown-item"
              >Change Password</router-link
            >
          </li>
          <li
            v-if="
              getAppClientPortalURLs?.length &&
              (currClientPortalUrl || pendingLatestAppCPUrl)
            "
          >
            <a
              :href="currClientPortalUrl || pendingLatestAppCPUrl"
              target="_blank"
              class="dropdown-item"
            >
              <div class="d-flex flex-direction-column">
                <span class="custom-d-flex">Go to Client Portal <SideArrow /></span>
                <span class="menu-subtext"
                  >Upload documents and <br />complete tasks</span
                >
              </div>
            </a>
          </li>
          <li>
            <a @click="logout" class="cursor-pointer dropdown-item">Sign out</a>
          </li>
        </ul>
      </div>
      <div v-else>
        <router-link to="/sign-in" class="text-secondary" role="button">
          Sign-In
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { eventBus } from "./../event-bus";
import DownArrow from "./DownArrow.vue";
import SideArrow from "./SideArrowSvg.vue";
import moment from "moment";

export default {
  name: "Navbar",
  components: { DownArrow, SideArrow },
  data() {
    return {
      openSideBar: false,
    };
  },
  computed: {
    ...mapGetters([
      "getProspectId",
      "getOrgId",
      "getUserId",
      "getCompanyName",
      "getIsAuthenticated",
      "getAssets",
      "getApplications",
      "getAppClientPortalURLs",
      "getActiveSection",
      "getSocialLogin",
      "getFacebookData",
      "getFacebookScope",
    ]),

    currClientPortalUrl() {
      let obj = this.getAppClientPortalURLs.find(
        (el) => el.prospectId === this.getUserId
      );

      return obj?.clientPortalURL || false;
    },

    pendingLatestAppCPUrl() {
      if (this.getApplications?.length) {
        let currentDate = moment().date("M/D/YYYY");

        let arrOfDays = this.getApplications.map((v) => {
          if (!v.endDate && v.startDate)
            return moment(currentDate).diff(moment(v.startDate), "days");
        });

        // Remove the negative numbers as they future dates
        let newArrOfDays = arrOfDays.filter((x) => {
          return x > -1;
        });

        if (newArrOfDays.length) {
          // Get the smallest positive number from the array
          let the_number = Math.min(...newArrOfDays);

          // returns index of the nearest start date
          let theIndex = arrOfDays.indexOf(the_number) || 0;

          let obj = this.getAppClientPortalURLs.find(
            (el) => el.prospectId === this.getApplications[theIndex].prospectId
          );

          return obj?.clientPortalURL || false;
        }
      }

      return false;
    },
  },
  created() {
    eventBus.$on("open-sidebar", (data) => {
      this.openSideBar = data;
    });
  },
  methods: {
    ...mapActions([
      "resetUserStore",
      "resetQuestionnaireStore",
      "resetAddressStore",
      "resetMcStore",
    ]),

    showSidebar() {
      this.openSideBar = !this.openSideBar;
      eventBus.$emit("mobile-view", this.openSideBar);
    },

    async logout() {
      // Google signout
      if (this.getSocialLogin === "Google") await this.$gAuth.signOut();

      // Facebook signout
      if (this.getSocialLogin === "Facebook" && this.getFacebookScope.logout) {
        await this.getFacebookScope.logout();
      }

      this.resetUserStore();
      this.resetQuestionnaireStore();
      this.resetAddressStore();
      this.resetMcStore();

      this.$router.push("/log-out");
    },
  },
};
</script>

<style>
.width-175 {
  width: 175px;
  max-width: 175px;
}
.flex-direction-column {
  flex-direction: column;
}
.custom-d-flex {
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.menu-subtext {
  font-size: 14px;
  font-weight: 400;
  color: #4d4d4d;
  margin-top: 5px;
  min-width: 175px;
  max-width: 175px;
}
.dropdown .dropdown-menu li .dropdown-item:active .menu-subtext {
  color: #fff !important;
}
</style>
